<template>
  <div>
    <CRow>
      <CCol sm="12">
        <KamsTable
          :items="kams"
          hover
          striped
          border
          small
          fixed
          caption="Kams"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import KamsTable from '../../components/kams/KamsTable.vue'
import ws from '../../services/kams';
import store from '../../store'

export default {
  name: 'Kams',
  components: { KamsTable },
  data: function () {
		return {
            kams: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await ws.get();

    if(response.type == "success"){
      this.kams = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.kams = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
